import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import Fade from 'react-reveal/Fade';
import { Link } from 'gatsby';
import { openModal, closeModal } from '@redq/reuse-modal';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
// import AnchorLink from 'react-anchor-link-smooth-scroll';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
// import TiltShape from '../../../components/TiltShape';
import Partner from 'common/src/assets/image/logo-remotementors.gif';
import {
  BannerWrapper,
  DiscountWrapper,
  DiscountLabel,
  VideoModalWrapper,
  VideoModal,
  PlayButton,
  // Button,
  ModalInnerWrapper,
} from './banner.style';
// import BannerImage from 'common/src/assets/image/logo-remotementors.gif';
import { ic_play_circle_filled } from 'react-icons-kit/md/ic_play_circle_filled';
import { play } from 'react-icons-kit/entypo/play';



const BannerSection = ({
  row,
  contentWrapper,
  discountAmount,
  discountText,
  title,
  description,
  subTitle,
  subTitleSecond,
  imageWrapper,
  buttonWrapper,
  buttonWrapperConfirm,
  button,
  fillButton,
  buttonNope,
  buttonBlue,
  buttonYes,
  imageArea
}) => {

  // close button for modal
  const CloseModalFormButton = () => (
    <Button
      className="modalCloseBtn light"
      variant="fab"
      onClick={() => handleNopeModal()}
      icon={<i className="flaticon-plus-symbol" />}
    />
  );
  const CloseModalConfirmButton = () => (
    <Button
      className="modalCloseBtn light"
      variant="fab"
      onClick={() => handleNopeModal()}
      icon={<i className="flaticon-plus-symbol" />}
    />
  );
  const CloseModalNopeButton = () => (
    <Button
      className="modalCloseBtn"
      variant="fab"
      onClick={() => closeModal()}
      icon={<i className="flaticon-plus-symbol" />}
    />
  );

  const ModalContentForm = () => (
    <ModalInnerWrapper>
      <iframe width="540" height="305" src="https://15944b92.sibforms.com/serve/MUIEAD1wCZeYihiJrdZMKqC0WtW0AmollBnsv7tzz86SjBv6tZUXD-kp0q3PBTdIESGqV8MRYMGt3ukb1N8whDQzOSRK0ogYSWjfuVNKyZE1bhevx7ML-g7QUj2b71ugJTQnFxMr9aBfWg4Uhk9ocGu6JiuCv0Dd1P9CaEcvFLZSryFXOzFNRF3ldQyqAG3Lh4k46onpyFn0JliL" frameborder="0" scrolling="auto" allowfullscreen style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%'}}></iframe>
    </ModalInnerWrapper>
  );

  const ModalContentNope = () => (
    <ModalInnerWrapper>
      <Box className="modalText">
        <Heading
          as="h1"
          content={[
            "Nope?",
            <span className="highlightText"> OK, je comprends</span>
          ]}
        />               
        <Text
          {...description}
          content={[
            "Tu veux prendre du niveau en création d'appli, en freelancing, en remote work",
            <strong><i> sans </i></strong>,
            "me laisser ton email ?",
            <br/>,
            <br/>
          ]}
        />               
        {/* <Heading
          as="h2"
          content={"Retrouve-moi sur Facebook"}
        />                */}        
        <Link href="https://www.facebook.com/okCharlo" target="_blank">
          <Heading
            as="h2"
            className="highlightText"
            // {...buttonBlue}
            onClick={() => closeModal()}  
            content="Retrouve-moi sur Facebook"/>        
        </Link>

      </Box>      
        <Button
          onClick={() => handleFormModal()}
          {...buttonYes}
            title={["Bon ok,", <br/>, <small>Je te laisse mon mail</small>]}/>          
    </ModalInnerWrapper>
  );

  const ModalContentConfirm = () => (
    <ModalInnerWrapper>
      {/* <ModalInner> */}
        <Box className="modalText">
          <Heading
            as="h1"
            className="highlightText"
            content="Pour que ce soit bien clair..."
          />        
          <Text
            {...description}
            content={[
              "Si tu me laisses ton prénom et ton email, je vais t'ajouter à ma liste.",
              <br/>,
              "Ça veut dire que tu vas recevoir ",
              <span className="highlightText">mon pack et mes hacks.</span> ,
              <br/>,
              "... entre autres.",
              <br/>,
              <br/>,
              "De temps en temps, je vais te partager d'autres tips, te donner des news, des accès à mes livestreams et ...",
              <br/>,
              "... aussi te vendre des choses. 😱",
              <br/>,
              <br/>,
              "Mais si je t'agace, si j'suis trop nul (t'as la ref?), tu pourras te désabonner n'importe quand."]}
          />
        </Box>
        <Box {...buttonWrapperConfirm}>
          <Button {...buttonNope}
            onClick={() => handleNopeModal()}
            title={["Non non non !", <br/>, <small>T'auras pas mon mail !</small>]}/>
          <Button
            onClick={() => handleFormModal()}
            {...buttonYes}
              title={["Vas-y envoie !", <br/>, <small>J'te kiffe suffisament.</small>]}/>
        </Box>
      {/* </ModalInner> */}
    </ModalInnerWrapper>
  );
    
  // modal handler
  const handleFormModal = () => {
    closeModal();

    openModal({
      config: {
        id: 'two', 
        className: 'video-modal',
        disableDragging: true,
        width: '100%',
        height: '100%',
      },
      component: ModalContentForm,
      componentProps: {},
      closeComponent: CloseModalFormButton,
      closeOnClickOutside: false,
    });
  };

  const handleNopeModal = () => {
    closeModal();

    openModal({
      config: {
        id: 'two', 
        className: 'video-modal',
        disableDragging: true,
        width: '100%',
        height: '100%',
      },
      component: ModalContentNope,
      componentProps: {},
      closeComponent: CloseModalNopeButton,
      closeOnClickOutside: true,
    });
  };

  const handleConfirmationModal = () => {
    openModal({
      config: {
        id: 'one',
        className: 'confirm-modal',
        disableDragging: true,
        width: '100%',
        height: '100%',
      },
      component: ModalContentConfirm,
      componentProps: {},
      closeComponent: CloseModalConfirmButton,
      closeOnClickOutside: false,
    });
  };

  return (
    <BannerWrapper id="banner_section">
      <Container>
        <Box {...row}>
          <Box {...contentWrapper}>
            <Box {...imageArea} className="imageArea">
              <Image src={Partner} alt="Domain Image" />
            </Box>
            <Heading
              {...title}
              as="h1"
              content={["Get our toolpack and top tips", <span className="highlightText"> for fullstack remote work.</span>]}
            />
            {/* <Heading
              {...subTitle}
              content="Tu recevras aussi des invitations pour mes livestreams."
            /> */}
            <Heading
              {...subTitleSecond}
              content={["It's free. Forever."]}
            />

            {/* <Box {...buttonWrapper}>
              <Button
                onClick={handleConfirmationModal}
                {...fillButton} title="I want your toolpack" />    
            </Box> */}
          </Box>

          <Box {...contentWrapper}>
            <Heading
              as="h1"
              {...subTitle}
              content={["Here is how it works.", <br/>, "Your give us your email and you get:"]}
            /> 
            <Heading
              as="h3"
              content="🎬 Vidéos, articles and access to our live"
            />             
            <Heading
              as="h3"
              content="🎺 Prime content and discount at our partners"
            />            
            <Heading
              as="h3"
              content="🎈 A self-assessment / self-coaching questionnaire to gain clarity on your project"
            />            
            <Heading
              as="h3"
              content="📒 My emotional design questionnaire"
            />
            <Heading
              as="h3"
              content="🛠 My best command line aliases to code faaaaassst. If you code you should like it."
            />              
            <Heading
              as="h3"
              content="📗 My scoping questionnaire for client projects"
            />
            <Heading
              as="h3"
              content="📘 My preliminary questionnaire for websites and app projects"
            />
            <Heading
              as="h3"
              content="🔐 A connection with us so you can help us define Remote Mentors"
            />
            <Heading
              as="h3"
              content="📝 My learnings from months of development and launching on Product Hunt"
            />
            <Heading
              as="h3"
              content="🎥 The video explaining the story behind the making of Remote Mentors"
            />
 
            {/* <Heading
              as="h1"
              className="highlightText"
              {...subTitle}
              content={["Interested ?", <br/>, "Then click leave us your info."]}
            />               */}
            <Box {...buttonWrapper}>

            <iframe width="540" height="600" src="https://15944b92.sibforms.com/serve/MUIEAAhohAnJ3swKnH8RbYGLU0IscTudL00MvGKQB079AgRVpqTD44A4t7TLDfo1nvU51U8HxiVbzBuHgOuoEc4KM22Ax71yQnpdIUzy46bToopZcrAMcsEnVMII3ZMPow3WT5xu8UVOUjf0oAs-rxb5YmrWKq0UDAWAaS2KT7KSUucoNsPqJ3bvGx5giO3gr0DzhvPEdRWbZ1mP" frameborder="0" scrolling="auto" allowfullscreen style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%'}}></iframe>
              {/* <Button
                onClick={handleConfirmationModal}
                {...fillButton} title="I want your toolpack" />     */}
            </Box>            
            <Box {...buttonWrapper}>
              <Link to="/">
                <Button {...fillButton} title="Take me back" />    
              </Link>
            </Box>             
          </Box>
          {/* <Box {...imageWrapper}>
            <Fade bottom>
              <VideoModalWrapper>
                <VideoModal>
                  <Image
                    className="bannerImage"
                    src={BannerImage}
                    alt="banner image"
                  />
                  <PlayButton tabIndex="0" onClick={handleFormModal}>
                    <Icon icon={play} size={40} />
                  </PlayButton>
                </VideoModal>
              </VideoModalWrapper>
            </Fade>
          </Box> */}
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  title: PropTypes.object,
  subTitle: PropTypes.object,
  subTitleSecond: PropTypes.object,
  description: PropTypes.object,
  imageWrapper: PropTypes.object,
  buttonWrapper: PropTypes.object,
  buttonWrapperConfirm: PropTypes.object,
  button: PropTypes.object,
  fillButton: PropTypes.object,
  buttonYes: PropTypes.object,
  buttonNope: PropTypes.object,
  buttonBlue: PropTypes.object,
  imageArea: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    // justifyContent: 'center',
  },
  contentWrapper: {
    width: '100%',
    maxWidth: '1100px',
    mb: ['0', '0', '0', '0', '0'],
    mt: ['0'],
  },
  title: {
    fontSize: ['32px', '36px', '40px', '40px', '74px'],
    color: '#0B0044',
    mb: ['40px', '45px', '45px', '45px', '45px'],
    lineHeight: ['1.2', '1.1', '1.1', '1.1', 1],
  },
  subTitle: {
    fontSize: ['26px', '28px', '32px', '36px', '44px'],
    color: '#0B0044',
    mb: ['40px', '45px', '45px', '45px', '45px'],
    lineHeight: ['1.3', '1.3', '1.2', '1.2', '1.1'],
  },
  subTitleSecond: {
    fontSize: ['26px', '28px', '32px', '36px', '44px'],
    color: '#7a82ae',
    mb: ['0', '0', '0', '0', '45px'],
    lineHeight: ['1.3', '1.3', '1.2', '1.2', '1.1'],
  },
  description: {
    fontSize: ['18px', '18px', '20px', '22px', '24px'],
    fontWeight: '400',
    color: '#6b6d96',
    lineHeight: '1.35',
    mb: '0',
    mt: ['10px', '15px', '15px', '15px', '15px'],
  },
  discountAmount: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: '#fff',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '400',
    color: '#fff',
    mb: 0,
    as: 'span',
  },
  fillButton: {
    type: 'button',
    fontSize: ['16px', '18px', '24px'],
    borderRadius: '3px',
    border: 'none',
    p: ['0px 18px', '16px 28px'],
    colors: 'primaryWithBg',
    minWidth: ['auto', '150px'],
    height: ['60px', 'auto'],
    minHeight: 'auto',
    textTransform: 'none'
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'start',
    mt: ['35px', '60px', '100px'],
    mb: ['35px', '60px', '100px'],
  },
  buttonWrapperConfirm: {
    flexBox: true,
    width: '100%',
    justifyContent: 'start',
    mt: ['0', '60px', '100px'],
    mb: ['35px', '60px', '100px'],
    flexDirection: ['column-reverse', 'row']
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'start',
    mt: ['35px', '60px', '100px'],
    mb: ['35px', '60px', '100px'],
  },
  buttonNope: {
    type: 'button',
    fontSize: ['16px', '18px', '24px'],
    borderRadius: '3px',
    border: 'none',
    p: ['0px 15px', '16px 24px'],
    color: 'textColor',
    colors: 'transparentBg',
    border: 'none',
    minWidth: ['auto', '150px'],
    height: ['60px', 'auto'],
    minHeight: 'auto',
    textTransform: 'none',
    marginRight: ['0', '20px'],
    marginBottom: ['20px', '0']
  },
  buttonYes: {
    type: 'button',
    fontSize: ['16px', '18px', '24px'],
    borderRadius: '3px',
    border: 'none',
    p: ['15px 15px', '16px 28px'],
    colors: 'secondaryWithBg',
    border: 'none',
    minWidth: ['auto', '150px'],
    height: ['60px', 'auto'],
    minHeight: 'auto',
    textTransform: 'none',
    marginRight: ['0', '20px'],
    marginBottom: ['20px', '0']
  },
  buttonBlue: {
    type: 'button',
    fontSize: ['16px', '18px', '24px'],
    borderRadius: '3px',
    border: 'none',
    p: ['15px 15px', '16px 28px'],
    colors: 'primaryWithBg',
    border: 'none',
    minWidth: ['auto', '150px'],
    height: ['60px', 'auto'],
    minHeight: 'auto',
    textTransform: 'none',
    marginRight: ['0', '20px'],
    marginBottom: ['20px', '0']
  },
  imageArea: {
    width: ['100%', '100%', '30%', '36%', '40%'],
    maxWidth: ['80px', '100px', '120px', '150px', '160px'],
    mb: ['1rem', '2rem'],
    mt: ['0', '0', '0', '10px', '10px'],
    p: ['0'],
  }  
};

export default BannerSection;
